<template>
    <page title="添加孩子" class="add-student-page" :hasBack="true" textAlign="left">
        <div class="upload-wrapper">
            <div class="upload-content">
                <van-uploader :after-read="afterRead">
                    <div class="upload">
                        <img
                            class="default"
                            v-if="!formValues.photo"
                            src="@/assets/images/addStudent/upload.png"
                            alt=""
                        />
                        <van-image v-else class="avatar" fit="cover" :src="formValues.photo" />
                    </div>
                </van-uploader>
                <div class="tips">请点击上传</div>
            </div>
            <div class="red-tips">本照片用于学校进出校门的人脸识别，请上传学生本人 正面半身照。</div>
        </div>
        <common-block class="name-wrapper" title="填写信息">
            <text-wrapper label="孩子姓名">
                <van-field v-model="formValues.sudentName" placeholder="请点击输入" />
            </text-wrapper>
        </common-block>
        <text-wrapper label="身份证号">
            <van-field v-model="formValues.idcard" placeholder="请点击输入" />
        </text-wrapper>
        <text-wrapper label="联系电话">
            <van-field v-model="formValues.perphone" placeholder="请点击输入" />
        </text-wrapper>
        <text-wrapper label="所属学校">
            <select-picker title="所属学校" :columns="schoolListColumns" type="school" @selectConfirm="selectConfirm" />
        </text-wrapper>
        <text-wrapper label="所属班级">
            <select-picker
                title="所属班级"
                ref="classRef"
                :columns="classListColumns"
                type="classId"
                @selectConfirm="selectConfirm"
                @selectChange="selectChange"
            />
        </text-wrapper>
        <text-wrapper label="所属关系">
            <select-picker
                title="所属关系"
                :columns="relationListColumns"
                type="relation"
                @selectConfirm="selectConfirm"
            />
        </text-wrapper>
        <text-wrapper label="走、住读">
            <select-picker
                title="走、住读"
                :columns="readTypeListColumns"
                type="oncampus"
                @selectConfirm="selectConfirm"
            />
        </text-wrapper>
        <div class="btn-wrapper">
            <van-button class="confirm-btn" round type="primary" @click="confirmSubmit">确认保存</van-button>
        </div>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import CommonBlock from '@/components/commonBlock/CommonBlock.vue';
import SelectPicker from '@/components/selectPicker/SelectPicker.vue';
import TextWrapper from '@/components/text/text';
import { reactive, ref } from 'vue';
import TimePicker from '@/components/timePicker/TimePicker';
import { Field, Button, Uploader, Image as VanImage } from 'vant';
import commonRequest from '@/services';
import { getStorage } from '@/utils/storage';
import paramsParse from '@/utils/paramsParse';
import { Toast } from 'vant';
import dayjs from 'dayjs';
import { useRouter } from 'vue-router';

export default {
    name: 'AddStudent',
    components: {
        [Page.name]: Page,
        [CommonBlock.name]: CommonBlock,
        [SelectPicker.name]: SelectPicker,
        [TextWrapper.name]: TextWrapper,
        [TimePicker.name]: TimePicker,
        [Field.name]: Field,
        [Button.name]: Button,
        [Uploader.name]: Uploader,
        [VanImage.name]: VanImage,
    },
    setup() {
        const router = useRouter();

        const schoolListColumns = ref([]);
        commonRequest({ action: 11019, type: 1, parentId: '0' }).then((res) => {
            if (res.data) {
                schoolListColumns.value = res.data.map((item) => ({
                    id: item.id,
                    text: item.schoolName,
                }));
            }
        });
        const classListColumns = reactive([{ values: [] }, { values: [] }, { values: [] }]);
        const relationListColumns = ref([]);
        commonRequest({ action: 10002, dCode: 'sys_student_parent_relation' }).then((res) => {
            if (res.data) {
                relationListColumns.value = res.data.map((item) => ({
                    id: item.value,
                    text: item.name,
                }));
            }
        });
        const readTypeListColumns = reactive([
            { text: '走读', id: 0 },
            { text: '住读', id: 1 },
        ]);

        const formValues = reactive({
            photo: '',
            sudentName: '',
            idcard: '',
            perphone: '',
            school: '',
            classId: '',
            relation: '',
            oncampus: '',
        });
        const classMsg = reactive({
            phaseId: null,
            phaseName: null,
            gradeId: null,
            gradeName: null,
            classId: null,
            className: null,
        });
        const selectConfirm = ({ type, value }) => {
            if (type === 'classId') {
                formValues.classId = value?.[2]?.id;
            } else {
                formValues[type] = value;
            }
            if (type === 'school') {
                commonRequest({ action: 11019, type: 2, parentId: value }).then((res) => {
                    if (res.data) {
                        classListColumns[0].values = res.data.map((item) => ({
                            id: item.id,
                            text: item.phaseName,
                        }));
                        if (res.data.length) {
                            classMsg.phaseId = res.data[0].id;
                            classMsg.phaseName = res.data[0].phaseName;
                            fetchGrade(res.data[0].id);
                        }
                    }
                });
            }
        };
        const selectChange = (selectData) => {
            if (selectData.type === 'classId') {
                const value = selectData.value;
                if (value?.[0].id !== classMsg.phaseId) {
                    classMsg.phaseId = value?.[0].id;
                    classMsg.phaseName = value?.[0].text;
                    fetchGrade(value?.[0].id);
                } else if (value?.[1].id !== classMsg.gradeId) {
                    classMsg.gradeId = value?.[1].id;
                    classMsg.gradeName = value?.[1].text;
                    fetchClass(value?.[1].id);
                }
            }
        };
        const fetchGrade = (parentId) => {
            commonRequest({ action: 11019, type: 3, parentId }).then((res) => {
                if (res.data) {
                    classListColumns[1].values = res.data.map((item) => ({
                        id: item.id,
                        text: item.gradeName,
                    }));
                    if (res.data.length) {
                        classMsg.gradeId = res.data[0].id;
                        classMsg.gradeName = res.data[0].phaseName;
                        fetchClass(res.data[0].id);
                    }
                }
            });
        };
        const fetchClass = (parentId) => {
            commonRequest({ action: 11019, type: 4, parentId }).then((res) => {
                if (res.data) {
                    classListColumns[2].values = res.data.map((item) => ({
                        id: item.id,
                        text: item.className,
                    }));
                }
            });
        };
        const afterRead = (file) => {
            const timestamp = Math.floor(new Date().getTime() / 1000);
            const signData = {
                action: 10001,
                type: 'images',
                timestamp,
            };
            const token = getStorage('token');
            if (token) {
                signData.token = token;
            }
            const sign = paramsParse(signData);
            const formData = new FormData();
            formData.append('action', 10001);
            formData.append('type', 'images');
            formData.append('timestamp', timestamp);
            formData.append('sign', sign);
            formData.append('token', token);
            formData.append('files', file.file);
            Toast.loading({
                message: '上传中...',
                forbidClick: true,
                loadingType: 'spinner',
            });
            commonRequest(formData).then((res) => {
                Toast.clear();
                if (res.data) {
                    formValues.photo = res.data[0]?.aliyunPath;
                }
            });
        };

        const confirmSubmit = () => {
            console.log(formValues, 'formValues');
            if (!formValues.sudentName) {
                Toast('请输入孩子姓名');
                return;
            }
            if (!formValues.photo) {
                Toast('请上传孩子照片');
                return;
            }
            if (!formValues.idcard) {
                Toast('请输入身份证号');
                return;
            }
            if (!formValues.perphone) {
                Toast('请输入联系电话');
                return;
            }
            if (!formValues.school) {
                Toast('请选择所属学校');
                return;
            }
            if (!formValues.classId) {
                Toast('请选择所属班级');
                return;
            }
            if (!formValues.relation) {
                Toast('请选择所属关系');
                return;
            }
            if (formValues.oncampus === '') {
                Toast('请选择走、住读');
                return;
            }

            commonRequest({ action: 11013, ...formValues }).then(() => {
                Toast.success('添加成功');
                setTimeout(() => {
                    router.replace('/studentList');
                }, 100);
            });
        };
        return {
            selectConfirm,
            formValues,
            schoolListColumns,
            classListColumns,
            relationListColumns,
            readTypeListColumns,
            afterRead,
            selectChange,
            confirmSubmit,
        };
    },
};
</script>
<style lang="less" scoped>
.add-student-page {
    padding-bottom: 20px;
    box-sizing: border-box;
}
.distance-days {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 5px;
    font-size: 13px;
    font-family: PingFang SC, serif;
    font-weight: 400;
    color: #999999;
}
.reason-input {
    padding: 5px 10px 5px 0;
}
.btn-wrapper {
    margin-top: 50px;
    text-align: center;
}
.upload-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 15px;
    margin-bottom: 11px;
    .tips {
        font-size: 13px;
        font-family: PingFang SC, serif;
        font-weight: 400;
        color: #999999;
        margin-top: 5px;
    }
}
.upload {
    width: 80px;
    height: 110px;
    background: #e2e2e2;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .default {
        width: 45px;
    }
    .avatar {
        width: 100%;
        height: 100%;
    }
}
.red-tips {
    height: 50px;
    background: #fff1f1;
    border-radius: 5px;
    padding: 7px 23px 0 10px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: PingFang SC, serif;
    line-height: 18px;
    color: #e64141;
}
.name-wrapper {
    /deep/ .common-content {
        padding-left: 0;
    }
}
/deep/ .van-field {
    padding: 15px 8px 11px;
    .van-field__control {
        line-height: 24px;
    }
    .van-field__control:-ms-input-placeholder {
        color: #999999;
    } /* Internet Explorer 10+ */

    .van-field__control::-webkit-input-placeholder {
        color: #999999;
    } /* WebKit browsers */

    .van-field__control::-moz-placeholder {
        color: #999999;
    } /* Mozilla Firefox 4 to 18 */

    .van-field__control:-moz-placeholder {
        color: #999999;
    } /* Mozilla Firefox 19+ */
}
</style>
